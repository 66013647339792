import React, { createContext, useContext, useState, useEffect } from "react";
import { AUTH_PROFILE, AUTH_TOKEN } from "constants/AuthConstant";
import { API_URL } from "constants/ApiConstant";
import { get } from "services/ApiService"; // Assuming you are making API calls via this service
import { useLocation } from "react-router-dom";
import { getCookie } from "services/CookieService";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [dataNotification, setDataNotification] = useState(null);
  const location = useLocation(); // Track location changes

  const getNotification = async () => {
    try {
      const res = await get(API_URL.notification + "/datatable");
      if (res?.status === 200) {
        setDataNotification(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching notification:", error);
    }
  };

  const checkSession = () => {
    const storedNotification = getCookie(AUTH_TOKEN);
    if (storedNotification) {
      getNotification();
    }
  };

  useEffect(() => {
    // This will trigger on every location change
    checkSession();
  }, [location]); // Dependency array includes 'location' to track changes

  return (
    <NotificationContext.Provider value={dataNotification}>
      {children}
    </NotificationContext.Provider>
  );
};

// Hook to use profile context
export const useGetNotification = () => {
  return useContext(NotificationContext);
};
