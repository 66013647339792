import axios from "axios";
import { autoLogout } from "./LogoutService";
import { AUTH_PROFILE, AUTH_TOKEN } from "constants/AuthConstant";
import { getCookie } from "./CookieService";

export function handleAxiosError(url, error) {
  if (error.code === "ECONNABORTED") {
    console.error(`Request to ${url} timed out`);
    return { status: 408, statusText: "Request Timeout" }; // Custom response for timeout
  } else if (error.message === "Network Error") {
    console.error(`Network error while connecting to ${url}`);
    return { status: 503, statusText: "Service Unavailable" }; // Custom response for network error
  } else if (error?.response?.status !== 401) {
    discordWebhook(url, error?.response);
  }
  return error?.response;
}

export async function getPaginate(url, page = 1, limit = 10, params = "") {
  const offset = (page - 1) * limit;
  const res = axios
    .get(url + "?offset=" + offset + "&limit=" + limit + params, {
      headers: {
        Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`,
        Accept: "application/json",
        "Content-Type": "application/json;",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else {
        discordWebhook(url, error?.response);
        return error?.response;
      }
    });

  return res;
}

export async function get(url, params = "") {
  const res = axios
    .get(url + params, {
      headers: {
        Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`,
        Accept: "application/json",
        "Content-Type": "application/json;",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else {
        discordWebhook(url, error.response);
        handleAxiosError(url, error);
        return error.response;
      }
    });

  return res;
}

export async function post(url, value) {
  const res = await axios
    .post(url, value, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;",
        Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else {
        discordWebhook(url, error.response);
        handleAxiosError(url, error);
        return error?.response;
      }
    });

  return res;
}

export async function put(url, value) {
  const res = axios
    .put(url, value, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;",
        Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else {
        discordWebhook(url, error.response);

        return error.response;
      }
    });

  return res;
}

export async function patch(url, value) {
  const res = axios
    .patch(url, value, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;",
        Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        autoLogout();
      } else {
        discordWebhook(url, error.response);

        return error.response;
      }
    });

  return res;
}

export async function del(url, value = {}) {
  const res = axios
    .delete(url, {
      headers: {
        Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`,
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        // autoLogout();
      } else {
        discordWebhook(url, error.response);

        return error.response;
      }
    });

  return res;
}

export async function discordWebhook(api, text) {
  const DISCORD_WEBHOOK =
    "https://discord.com/api/webhooks/1335799951304822815/hoNnsRJT649KFNVqW1EkEGM0TgQvs7lS4gQ090kzrFzJ4RkTm-91-j_cK5HKMR54mJcV";
  // Create a formatted string instead of an object
  const user = JSON.parse(localStorage.getItem(AUTH_PROFILE));
  const messageString = `\`\`\`json
  User: ${user?.name}
  URL: ${window.location.href}
  API: ${JSON.stringify(api, null, 2)}
  Message: ${JSON.stringify(text, null, 2)}
  \`\`\``;
  const payload = {
    content: messageString,
  };
  const res = await axios
    .post(DISCORD_WEBHOOK, JSON.stringify(payload), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => res)
    .catch((error) => error.response);
  return res;
}

export async function exportData(url, params = "", filename, extension) {
  const res = await axios
    .post(
      url + params,
      {},
      {
        headers: {
          Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`,
        },
        responseType: "blob",
      }
    )
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename + "." + extension);
      document.body.appendChild(link);
      link.click();

      return res;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        // autoLogout();
      } else {
        return error.response;
      }
    });

  return res;
}

export async function exportGetData(url, params = "", filename, extension) {
  const res = await axios
    .get(url + params, {
      headers: {
        Authorization: `Bearer ${getCookie(AUTH_TOKEN)}`,
      },
      responseType: "blob",
    })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename + "." + extension);
      document.body.appendChild(link);
      link.click();

      return res;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        // autoLogout();
      } else {
        return error.response;
      }
    });

  return res;
}
